import React from "react";
import styled  from "styled-components";

const Container = styled.div`
min-height:30vh;
border: 2px solid lightgray;
border-radius: 5px;
padding:10px;
position:fixed;
max-width:calc(40% - 40px);
`;

const TopContainer = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
`;

const TitleText = styled.h2`
margin:0;
`;

const Text = styled.p`
margin:0;
`;

const DateText = styled.p`
position:absolute;
bottom:0;
`;

const DescText = styled.p`
margin-bottom:50px;
`;

const HeartImage = styled.img`
width:25px;
height:25px;
margin-left:10px;
`;

const InterestedContainer = styled.div`
display:flex;
align-items:center;
position:absolute;
bottom:0;
right:20px;
`;

const JobItem = ({Title, Description, Rate, Location, Date, HeartClick, HeartState}) => {
    return (
        <Container>
            <TopContainer>
                <TitleText>{Title}</TitleText>
                <Text>${Rate} Per hour</Text>
            </TopContainer>
            <Text>{Location}</Text>
            <DescText>{Description}</DescText>
            <DateText>Posted {Date}</DateText>
            <InterestedContainer onClick={HeartClick}>
                <p>Interested</p>
                <HeartImage src={HeartState} alt="Heart"></HeartImage>
            </InterestedContainer>
        </Container>
    )
}

JobItem.defaultProps = {
    Title: "",
    Description: "",
    Rate: "",
    Location: "",
    Date: ""
}

export default JobItem;