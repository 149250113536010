import {React, useEffect, useState} from "react";
import JobItem from "../components/JobItem";
import NavigationBar from '../components/Navigation Bar';
import JobDescription from '../components/JobDescription';
import styled from "styled-components";
import axios from "axios";

import FakeDB from '../FakeDB.json';
import HeartRed from '../images/heart-red.png';
import HeartWhite from '../images/heart-white.png';


const Page = styled.div`
width:100vw;
display:flex;
justify-content:center;
margin-top:120px;
`;

const ContentContainer = styled.div`
width:80%;
display:flex;
`;

const JobListContainer = styled.div`
    flex:1;
    display:flex;
    flex-direction:column;
    margin-right:10px;
    max-width:50%;
`;

const JobDescriptionCont = styled.div`
    flex:1;
    margin-left:10px;
  
`;

const MyJobs = () => {

    const [userID, setUserID] = useState(80);

    const [JobList, setJobList] = useState([]);
    const [JobTitle, setJobTitle] = useState();
    const [JobDesc, setJobDescription] = useState();
    const [JobDate, setJobDate] = useState();
    const [JobRate, setJobRate] = useState();
    const [JobLocation, setJobLocation] = useState();
    const [Heart, setHeart] = useState();

    const [isInitialRender, setIsInitialRender] = useState(true);
    const [CurrentID, setCurrentID] = useState();

    // When the page loads, set the heart to the correct colour, and the users ID
    
    
    useEffect(()=>{
            SetDefaultValues();
            setUserID(80);
            getJobs();
        
    },[userID, JobList]);

     async function getJobs() {
        // var resp = await axios.get("URL");
        var resp = FakeDB;
        // setJobList(resp);

        const newArray = resp.filter(e => {
            return e.PrimaryWorker === userID;
          });
          if (isInitialRender) {
            setIsInitialRender(false);
            setJobList(newArray);
          }
     }

     async function SetDefaultValues() {
        setJobTitle(JobList[0].Title);
        setJobDescription(JobList[0].Description);
        setCurrentID(JobList[0].Id);
        setJobDate(JobList[0].Date);
        setJobRate(JobList[0].Rate);
        setJobLocation(JobList[0].Location);

        if (JobList[0].PrimaryWorker === userID) {
            setHeart(HeartRed);
        } else if (!JobList[0].PrimaryWorker === userID) {
            setHeart(HeartWhite);
        }
     }

    // When clicking on a new job, set the description box's text
    async function setDescriptionBox(o) {
        setCurrentID(JobList.find(x => x.Id === o.Id).Id);
        setJobTitle(JobList.find(x => x.Id === o.Id).Title);
        setJobDescription(JobList.find(x => x.Id === o.Id).Description);
        setJobDate(JobList.find(x => x.Id === o.Id).Date);
        setJobRate(JobList.find(x => x.Id === o.Id).Rate);
        setJobLocation(JobList.find(x => x.Id === o.Id).Location);
        
        if (JobList.find(x => x.Id === o.Id).BackupWorkers.includes(userID)){
            setHeart(HeartRed);
            console.log("Heart set to red")
        } else if (!JobList.find(x => x.Id === o.Id).BackupWorkers.includes(userID)) {
            setHeart(HeartWhite);
            console.log("Heart set to white")
        }
    }

    // When clicking on the Heart, set to opposite colour, and change UserID in database
    const AddInterestedUser = () => {
        if (Heart === HeartWhite){
            setHeart(HeartRed);
            JobList.find(x => x.Id === CurrentID).BackupWorkers.push(userID);
            // JobList[CurrentID-1].PrimaryWorker.push(userID);
        } else if (Heart === HeartRed) {
            setHeart(HeartWhite);
            var IDIndex = JobList.find(x => x.Id === CurrentID).BackupWorkers.indexOf(userID);
            JobList.find(x => x.Id === CurrentID).BackupWorkers.splice(IDIndex, 1);
        }
    }

    return (
        <div>
            <NavigationBar/>
            <Page>
                <ContentContainer>
                    <JobListContainer>
                        {JobList.map((o) => {
                            if (o.PrimaryWorker === userID) {
                            return (
                                <JobItem
                                key={o.Id}
                                Clicked={()=>{setDescriptionBox(o)}}
                                Title={o.Title}
                                Description={o.Description}
                                Rate={o.Rate}
                                Location={o.Location}
                                Date={o.Date}
                                />
                            )}
                        }
                        )}
                    </JobListContainer>
                    <JobDescriptionCont>
                        <JobDescription
                                Title={JobTitle}
                                Description={JobDesc}
                                Rate={JobRate}
                                Location={JobLocation}
                                Date={JobDate}
                                HeartClick={AddInterestedUser}
                                HeartState={Heart}
                        />
                    </JobDescriptionCont>
                </ContentContainer>
            </Page>
        </div>
    )
}

export default MyJobs;