import React from "react";
import styled  from "styled-components";

const Container = styled.div`
width:100%;
height:100px;
box-shadow: 2px 2px 10px lightgray;
display:flex;
align-items:center;
margin-bottom:20px;
position:fixed;
top:0;
background-color:white;
`;

const Link = styled.div`
display:flex;
justify-content:center;
align-items:center;
font-size:24px;
`;

const Linkcont = styled.div`
 display:flex;
 margin-left:10%;
 width:500px;
 justify-content:space-between;
`;

const NavigationBar = () => {
    return (
        <Container>
            <Linkcont>
                <Link><a href="/jobs">Available Jobs</a></Link>
                <Link><a href="/interested-jobs">Jobs Of Interest</a></Link>
                <Link><a href="/my-jobs">My Jobs</a></Link>
            </Linkcont>
        </Container>
    )
}

export default NavigationBar;