import React from "react";
import styled from "styled-components";
// import "./Login.css";
import LoginVector from '../images/login-vector.svg';
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Page = styled.div`
    width:100vw;
    height:100vh;
    display:flex;
`;

const PageLeft = styled.div`
    flex:1;
    padding:10%;
    display:flex;
    align-items:center;
    justify-content: center;
`;

const PageRight = styled.div`
    flex:1;
    padding:10%;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    background-color: #3449be;
`

const LoginTitle = styled.h1`
    padding-bottom: 30px;
    color:white;
`

const Vector = styled.img`
    max-width:400px;
`

const LoginContainer = styled.div`
    width:350px;
`

const LoginField = styled.input`
    border:2px solid lightgray;
    background-color: rgb(237, 237, 237);
    border-radius:8px;
    padding:8px;
    width:94%;
    margin-bottom:20px;
`

const LoginButton = styled.button`
    width:100%;
    height:40px;
    color:white;
    border:none;
    background-color: #3449be;
    border-radius: 5px;
    :hover {
        background-color: #415ae5;
        cursor: pointer;
    }
`

const LoginError = styled.p`
display:none;
color:red;
margin-top:0;
margin-bottom:20px;
text-align:center;
`;

const Login = () => {
    let navigate = useNavigate();

    const LoginSubmit = async () => {
        var Username = document.getElementById("username").value;
        var Password = document.getElementById("password").value;

        var resp = await axios.post("https://httpbin.org/post",{Username:Username, Password:Password});
        console.log(resp.data);

        if (resp.data.json.Password === "Test") {
            navigate("/jobs");
        } else {
            document.getElementById("ErrorMessage").style.display = "block";
        }
        } 

    return (
        <Page>
            <PageLeft>
                <LoginContainer> 
                    <h1>Login</h1>
                        <div>
                            <LoginField id="username" type="text" placeholder="Username"></LoginField>
                        </div>
                        <div>
                            <LoginField id="password" type="password" placeholder="Password"></LoginField>
                        </div>
                        <LoginError id="ErrorMessage">Sorry, incorrect password.</LoginError>
                        <LoginButton onClick={LoginSubmit}>Login</LoginButton>
                </LoginContainer>
            </PageLeft>
            <PageRight>
                <div>
                    <LoginTitle>Welcome back.</LoginTitle>
                    <Vector src={LoginVector} alt="People communicating"></Vector>
                </div>
            </PageRight>
        </Page>
    )
}

export default Login;