import React from "react";
import styled  from "styled-components";

const Container = styled.div`
padding:10px;
border-radius:5px;
border:2px solid lightgray;
margin-bottom:20px;
`;

const TopContainer = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
`;

const DescText = styled.p`
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
`;

const TitleText = styled.h2`
margin:0;
`;

const Text = styled.p`
margin:0;
`;

const JobItem = ({Title, Description, Rate, Location, Date, Clicked}) => {
    return (
        <Container onClick={Clicked}>
            <TopContainer>
                <TitleText>{Title}</TitleText>
                <Text>${Rate} Per hour</Text>
            </TopContainer>
            <Text>{Location}</Text>
            <DescText>{Description}</DescText>
            <Text>{Date}</Text>
        </Container>
    )
}

JobItem.defaultProps = {
    Title: "Title",
    Description: "Job description",
    Rate: "0",
    Location: "BC, Canada",
    Date: "Jul. 15th, 2022"
}

export default JobItem;