import './App.css';
import Login from '../src/pages/login';
import AllJobs from '../src/pages/AllJobs';
import MyJobs from '../src/pages/MyJobs';
import InterestedJobs from '../src/pages/InterestedJobs';
import {BrowserRouter, Routes, Route} from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login/>}></Route>
        <Route path="/jobs" element={<AllJobs/>}></Route>
        <Route path="/my-jobs" element={<MyJobs/>}></Route>
        <Route path="/interested-jobs" element={<InterestedJobs/>}></Route>
        <Route path="*" element={<AllJobs/>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
